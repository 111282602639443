;(function($, Drupal) {
  var isMobile,
      isSafari,
      langCode;

  isMobile = $(window).width() < 768;
  langCode = $('html').attr('lang');

  Drupal.behaviors.style = {
    attach: function(context, settings) {
      $('.js-select2').each(function(_, el) {
        let dropdownCssClass = '';
        if ($(el).width() > 110) {
          dropdownCssClass = '-extra';
        } else {
          dropdownCssClass = '-center';
        }
        $(el).select2({
          theme: 'bootstrap',
          width: '100%',
          minimumResultsForSearch: -1, // hide input
          dropdownCssClass: dropdownCssClass
        });
      })

      $('.js-select2')
        // .removeClass('select2-hidden-accessible')  // debug
        .each(function() {
          const $this = $(this)
          const nSelect = $this.next('.select2-container')
          if (nSelect.length > 0) {
            $this.hasClass('input-sm') && nSelect.addClass('-sm')
            $this.hasClass('input-lg') && nSelect.addClass('-lg')
          }
        })

      /**
       * Slick
       */
      $('[data-slick]').slick();

      /**
       * Extend Boostrap
       */
      $('.panel-collapse')
        .on('show.bs.collapse', function() {
          $(this)
            .parent()
            .addClass('-opened')
        })
        .on('hide.bs.collapse', function() {
          $(this)
            .parent()
            .removeClass('-opened')
        });

        /**
         * Prevent scroll in number-type
         */
      $(document).on('wheel', 'input[type=number]', function (e) {
          $(this).blur();
      });
    }
  }

  // TODO: [L] load conditionally
  Drupal.behaviors.cms = {
    attach: function(context, settings) {
      $('#paydollar-payment-cptb-form-redirect,\
        #cybersource-payment-sawm-form-redirect').on('submit', function() {
          $(this).find('button[type="submit"], .form-submit').attr('disabled', 'disabled');
        });

      /*
       * Header
       */
      // `mediaCheck` from matchmedia.js
      mediaCheck({
        media: `(max-width: 768px)`,
        entry: () => {
          $('.header-donation-button').insertBefore('.navbar-toggle')
        },
        exit: () => {
          $('.header-donation-button').insertBefore('#block-locale-language')
        }
      });

      /**
       * Backtop
       */
      (function(){
        $('#page-backtop').on('click', function() {
          var rootElement;

          // fix scrollTop not work in safari & chrome in XS
          if ($('html').scrollTop()) {
            rootElement = 'html';
          } else if($('body').scrollTop()) {
            rootElement = 'body';
          } else {  // already at the Top of root
            return false;
          }

          $(rootElement)
            .stop()
            .animate({ scrollTop: 0 }, 700, 'swing')
        });
      })();
    }
  }
})(jQuery, Drupal)
